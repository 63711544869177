import * as React from 'react';
import {
    AppBar,
    Dialog,
    Fab, FormControlLabel, FormGroup, FormLabel,
    IconButton,
    Radio, RadioGroup, Snackbar,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {Fragment} from "react";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

export default function ExpenseCreate(props) {

    const [newExpenseWindowIsOpen, setNewExpenseWindowIsOpen] = React.useState(false)
    const [snackbarIsOpen, setSnackbarIsOpen] = React.useState(false);

    const [newExpenseName, setNewExpenseName] = React.useState("");
    const [newExpenseType, setNewExpenseType] = React.useState("simple");

    const nameChangeHandler = (event) => {
        setNewExpenseName(event.target.value);
    }

    const newExpenseTypeChangeHandler = (event) => {
        setNewExpenseType(event.target.value);
    }

    const submitForm = (event) => {
        event.preventDefault();

        let newExpense = {
            name: newExpenseName,
            key: newExpenseName.concat("-key"),
            goals: [
                {
                    goal: 0.00,
                    allocated: 0.00
                }
            ]
        }

        props.addExpenseFunc(newExpense)
        setSnackbarIsOpen(true)
        setNewExpenseWindowIsOpen(false)
        setNewExpenseName("")
        setNewExpenseType("simple")
    }

        return(
        <Fragment>
            <Snackbar
                open={snackbarIsOpen}
                autoHideDuration={3000}
                onClose={() => {setSnackbarIsOpen(false)}}
                message="Created Expense"
            />
            <Fab size="large" color="secondary" aria-label="add"
                 sx={{ position: 'fixed', bottom: 80, right: 30}}
            onClick={()=>{setNewExpenseWindowIsOpen(!newExpenseWindowIsOpen)}}>
                <AddIcon />
            </Fab>
            <Dialog
                fullScreen
                open={newExpenseWindowIsOpen}>
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        onClick={()=>setNewExpenseWindowIsOpen(false)}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Create a new Expense
                    </Typography>
                </Toolbar>
                </AppBar>
                <form onSubmit={submitForm}>
                    Expense Name
                    <FormGroup sx={{ padding: "10px" }}>
                  <TextField label="Expense Name" value={newExpenseName} onChange={nameChangeHandler} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel id="demo-radio-buttons-group-label">Expense Type</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="simple"
                            value={newExpenseType}
                            onChange={newExpenseTypeChangeHandler}
                            name="radio-buttons-group"
                            sx={{paddingTop: "10px"}}

                        >
                            <FormControlLabel value="simple" control={<Radio />} label="Simple" />
                            <FormControlLabel value="bill" control={<Radio disabled/>} label="Bill" />
                            <FormControlLabel value="once" control={<Radio disabled/>} label="One-off" />
                        </RadioGroup>
                    </FormGroup>
                    <Button sx={{ mt: 1, mr: 1}} type="submit" variant="outlined">
                        Create Expense
                    </Button>
                </form>
            </Dialog>
            </Fragment>
    );
}