import * as React from 'react';
import {Fragment} from 'react';

export function ReceiptsTab(props){
//     <Card variant="outlined">
//         <CardHeader title={<FormControlLabel value="goal" control={<Radio />} label="Goal" />} />
//         <CardContent>Goal envelopes set aside money for a one-time goal, like a vacation or major purchase.</CardContent>
//     </Card>
//
//     <Card variant="outlined">
//         <CardHeader title={<FormControlLabel value="bill" control={<Radio />} label="Bill" />} />
//         <CardContent>Bill envelopes set aside money from every paycheck to help you stay on top of your
//             recurring bills, and even help you get ahead.
//         </CardContent>
//     </Card>
//

    return (

        <Fragment>
        Hi
        </Fragment>
    );
}