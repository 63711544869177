import Card from "@mui/material/Card";
import {CardActionArea, CardHeader} from "@mui/material";
import * as React from "react";
import CardContent from "@mui/material/CardContent";

export function SafeToSpendCard(props) {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const CardTitle = "SafeToSpend: " + formatter.format(props.Amount)
    const AcctBalance = formatter.format(2396.19)

    return (
        <Card variant="outlined" sx={{width: '100%'}}>
            <CardActionArea onClick={
                function () {
                    console.log("Card Clicked")
                }
            }>
                <CardHeader title={CardTitle}/>
                <CardContent>
                    Account Balance: {AcctBalance} <br />
                    Next Paycheck: ~$1500 ~Sept 30th
                </CardContent>
            </CardActionArea>
        </Card>
    );
}