import Card from "@mui/material/Card";
import * as React from "react";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import {CardActionArea, CardHeader} from "@mui/material";
import Box from "@mui/material/Box";
import {DateTime} from "luxon";


export function ExpenseCard(props){

    const clickMessage = "Card Clicked: ".concat(props.Name)

    return (
            <Card variant="outlined" sx={{width: '100%'}}>
                <CardActionArea onClick={
                    function(){
                        console.log(clickMessage)
                    }
                }>
                    <CardHeader title={props.Name} />
                    <CardContent>
                        {props.children}
                    </CardContent>
                </CardActionArea>
            </Card>
    );
}


export function GoalBox(props) {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const allocatedString = formatter.format(props.allocated)

    let goalString = ""
    if (props.goal !== 0){
        goalString = " / ".concat(formatter.format(props.goal))
    }

    const progress = Math.min(100.0, props.allocated / props.goal * 100.0)

    const goalDate = DateTime.fromISO(props.date)

    let dateString = ""
    if (goalDate.invalid) {
        dateString = ""
    } else {
        dateString = goalDate.toLocaleString({month: "long", day: "numeric"})
    }

    return (
        <Box sx={{width: '100%', padding: '5px' }} >
            <div style={{float: "left"}}>{dateString}</div> <div style={{float: "right"}}>{allocatedString}{goalString} </div>
            <LinearProgress variant="determinate" value={progress} color="success" sx={{height: '15px', borderRadius: '5px', float: "bottom", width: "100%"}}/>
        </Box>
    );
}