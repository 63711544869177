import {TransactionList} from "./TransactionList";
import {Fragment} from "react";
import {SafeToSpendCard} from "./SafeToSpendCard";


export function TransactionsTab(props){
    return (

        <Fragment>
            <SafeToSpendCard Amount={123.45}/>
            <TransactionList/>
        </Fragment>
    );
}