import {CardActionArea} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as React from "react";

export function TransactionCard(props){


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const amountString = formatter.format(props.Amount)
    return (
    <Card variant="outlined" sx={{width: '100%'}}>
        <CardActionArea onClick={
            function () {
                console.log("Card Clicked")
            }
        }>
            <CardContent>
                <div>{props.Name}</div>
                <div style={{textAlignLast: "right"}}>{amountString}</div>
            </CardContent>
        </CardActionArea>
    </Card>
);
}