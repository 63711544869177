import {ExpenseList} from "./ExpenseList";
import * as React from "react";
import {Fragment} from "react";
import {SafeToSpendCard} from "./SafeToSpendCard";
import ExpenseCreate from "./ExpenseCreate";
import {GetExpenses} from "../amlen-rest/Expenses";


export function ExpensesTab(props){

    const [myExpenses, setExpenses] = React.useState(GetExpenses);

    const addExpense = (expense) => {
        let newExpenses = [expense, ...myExpenses];
        setExpenses(newExpenses)
    }

    return (
        <Fragment>
            <ExpenseCreate addExpenseFunc={addExpense}/>
            <SafeToSpendCard Amount="123.45"/>
            <ExpenseList expenses={myExpenses}/>
        </Fragment>
    );
}