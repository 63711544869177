import List from '@mui/material/List';
import * as React from "react";
import {GetTransactions} from "../amlen-rest/Transactions";
import {ListItem} from "@mui/material";
import {TransactionCard} from "./TransactionCard";

/*
TODO: Use a virtualized list here. https://mui.com/material-ui/react-list/#virtualized-list

TODO: Use an infinite loader here. https://www.npmjs.com/package/react-window-infinite-loader

TODO: Group transactions by date, and then use a sticky header to show that date https://mui.com/material-ui/react-list/#sticky-subheader
 */

export function TransactionList(){

    const myTransactions = GetTransactions()

    return(
        <List dense>
            {
                myTransactions.map((MyTransaction) => (
                        <ListItem key={(MyTransaction.key.concat("-ListItem"))}>
                            <TransactionCard Name={MyTransaction.name} Amount={MyTransaction.amount} key={MyTransaction.key.concat("-card")}>
                            </TransactionCard>
                        </ListItem>
                    ) // map lambda
                ) // map call
            }
        </List>
    );
}