import './App.css';
import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import {
    AppBar,
    BottomNavigation,
    BottomNavigationAction,
    CssBaseline,
    IconButton,
    InputBase,
    Paper,
    styled,
    ThemeProvider,
    Toolbar,
    Typography
} from "@mui/material";
import {TransactionsTab} from "./amlen-components/TransactionsTab";
import {ExpensesTab} from "./amlen-components/ExpensesTab";
import {ReceiptsTab} from "./amlen-components/ReceiptsTab";
import Box from "@mui/material/Box";
import {Search} from "@mui/icons-material";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MenuIcon from '@mui/icons-material/Menu';

// let darkTheme = createTheme({
//                 // palette: {
//                 //   mode: 'dark',
//                 // },
//               })


// darkTheme.palette.BrandingMain = { backgroundColor: green[500], color: '#000' };


const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {

    const [bottomNavValue, setBottomNavValue] = React.useState(0);
    const [mainContent, setMainContent] = React.useState(<TransactionsTab/>);


    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);

    function getNewMainContent(newTab) {
        if(newTab === 0){
            return (
              <TransactionsTab/>
            );
        }

        if(newTab === 1){
            return (
                <ExpensesTab />
            )
        }

        if (newTab === 2) {
            return(

            <ReceiptsTab />)
        }
    }

    const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

   const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));


return (

      <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
        <CssBaseline/>

    <Box sx={{flexGrow: 1}}>
          <AppBar position="sticky">
            <Toolbar>
              <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{mr: 2}}
                  onClick={void(0)}
              >
                <MenuIcon/>
              </IconButton>
              <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                Amlen
              </Typography>

              <Search>
                <SearchIconWrapper>
                  <SearchIcon/>
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{'aria-label': 'search'}}
                />
              </Search>

              <Button color="inherit">Login</Button>
            </Toolbar>
          </AppBar>
        </Box>

          <div id="main-content">
              {mainContent}
          </div>

                  <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
              <BottomNavigation
                  showLabels
                  value={bottomNavValue}
                  onChange={(event, newValue) => {
                      setBottomNavValue(newValue);
                      setMainContent(getNewMainContent(newValue))
                  }}
              >
                  <BottomNavigationAction
                      label="Transactions"
                      icon={<ReceiptLongIcon/>}
                  />
                  <BottomNavigationAction
                      label="Expenses"
                      icon={<AccountBalanceIcon/>}
                  />
                  <BottomNavigationAction
                      label="Receipts"
                      icon={<ReceiptLongIcon/>}/>
              </BottomNavigation>
          </Paper>

      </ThemeProvider>
      </ColorModeContext.Provider>
);
}

export default App;

