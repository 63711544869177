import List from '@mui/material/List';
import * as React from "react";
import {ListItem} from "@mui/material";
import {ExpenseCard, GoalBox} from "./ExpenseCard";


export function ExpenseList(props){
    return(
    <List dense>
        {
            props.expenses.map((MyExpense) => (
                    <ListItem key={(MyExpense.key.concat("-ListItem"))}>
                        <ExpenseCard Name={MyExpense.name} Goals={MyExpense.goals} key={MyExpense.key.concat("-card")}>
                        {
                            MyExpense.goals.map((goalDetails, index) => (
                                <GoalBox {...goalDetails} key={MyExpense.name.concat("-",goalDetails.date)} />
                            ))
                        }
                        </ExpenseCard>
                    </ListItem>
            ) // map lambda
            ) // map call
        }
    </List>
    );
}