export function GetTransactions() {
    const output = [
        {
            name: "CenterPoint Energy",
            key: "CenterpointKey",
            amount: -25
        },
        {
            name: "Xcel Energy",
            key: "Xcelkey",
            amount: -200
        },
        {
            name: "Meteor Cocktail Bar",
            key: "meteorKey",
            amount: -75
        },
        {
          name: "Paycheck: BigCo, Inc",
          key:"PaycheckKey",
            amount: 1500,
        },
        {
            name: "Starbucks Coffee Co  ",
            key: "Starbucks Key",
            amount: -7
        },
    ]

    return output
}