export function GetExpenses() {
    const output = [

        {
         name: "🏡 Mortgage Payment",
            key: "Mortgage",
            goals: [
                {
                    date: "2024-09-05",
                    goal: 1284.69,
                    allocated: 1284.69
                },
                {
                    date: "2024-10-05",
                    goal: 1284.69,
                    allocated: 423.94
                },
                {
                    date: "2024-11-05",
                    goal: 1284.69,
                    allocated: 0,
                },
            ]

        },

        {
            name: "🔥 Centerpoint Energy",
            key: "CenterpointKey",
            goals: [
                {
                    date: "2024-08-30",
                    goal: 24.55,
                    allocated: 24.55
                },
                {
                    date: "2024-09-29",
                    goal: 25,
                    allocated: 17.95,
                },
                {
                    date: "2024-10-28",
                    goal: 30,
                    allocated: 0
                }
            ]
        },

        {
            name: "⚡ Xcel Energy",
            key: "Xcel",
            goals: [
                {
                    date: "2024-09-18",
                    goal: 221.68,
                    allocated: 221.68
                },
                {
                    date: "2024-10-19",
                    goal: 130,
                    allocated: 93.32
                },
                {
                    date: "2024-11-18",
                    goal: 155,
                    allocated: 0
                }
            ]
        },

        {
            name: "🎵 Spotify",
            key: "Spotify",
            goals: [
                {
                    date: "2024-09-03",
                    goal: 13.07,
                    allocated: 13.07,
                },
                {
                    date: "2024-10-03",
                    goal: 13.07,
                    allocated: 6.54,
                },
                {
                    date: "2024-11-03",
                    goal: 13.07,
                    allocated: 0,
                }
            ]
        },
        {
            name: "🛜 CenturyLink",
            key: "Centerlink",
            goals: [
                {
                    date: "2024-09-02",
                    goal: 65,
                    allocated: 65
                },
                {
                    date: "2024-10-02",
                    goal: 65,
                    allocated: 32.50
                },
                {
                    date: "2024-11-02",
                    goal: 65,
                    allocated: 0
                }
            ]
        },
        {
            name: "🧑‍💻 IntelliJ",
            key: "IntelliJ",
            goals: [
                {
                    date: "2024-12-01",
                    goal: 120,
                    allocated: 89.50
                }
            ]
        }
    ]

    return output
}